.l-topMenu h2::before{
    margin: 0 10px 0 0;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f518";
    color: rgb(250, 160, 77)
}

.c-sectionTitle::before {
    margin: 0 7px 0 0;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f129";
    font-size: 18px;
    color: rgb(250, 160, 77)

}

.l-headerNav__list a::before {
    margin: 0 10px 0 0;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f141";
    color: rgb(250, 160, 77)
}